
import { PropType } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IUser } from "@/types/User";
const ReceiptProps = Vue.extend({
  props: {
    modal: Object,
    receipt: Object,
    users: Array as PropType<IUser[]>,
  },
});
@Component
export default class viewInstructedReceiptModal extends ReceiptProps {
  getUserById(id: string): string {
    const users: IUser[] = this.users;
    const found = users.find((element) => element.id == id);
    if (found != undefined) return found.userName || "unknown";
    return "unknown";
  }
}
