
import { Component, Vue } from "vue-property-decorator";
import { UserService } from "@/services/user-service";
import viewReceiptModal from "@/components/ViewReceiptModal.vue";
import viewInstructedReceiptModal from "@/components/ViewInstructedReceiptModal.vue";
import { IUser } from "@/types/User";
import { IResponseData } from "@/types/ResponseData";
import { Defaults } from "@/helpers/Defaults";
import { ApiService } from "@/services/api-services";
@Component({
  name: "Receipt",
  components: {
    viewReceiptModal,
    viewInstructedReceiptModal,
  },
})
export default class Receipt extends Vue {
  loading = true;
  iLoading = true;
  receiptsSkeleton = true;
  iReceiptsSkeleton = true;
  apiService = new ApiService();
  userService = new UserService();
  defaults = new Defaults();
  receiptResponseData: IResponseData = this.defaults.responseData;
  iReceiptResponseData: IResponseData = this.defaults.responseData;
  users: IUser[] = [];
  lPageNumber = 1;
  iPageNumber = 1;

  async initialize(): Promise<void> {
    const requestParams = this.defaults.requestParams;
    requestParams.includes =
      '["Dispatch","Dispatch.Driver","Dispatch.LoadingPlan","Dispatch.LoadingPlan.Warehouse","Dispatch.LoadingPlan.District","Dispatch.LoadingPlan.Transporter"]';
    const receiptResponse = await this.apiService.getAll(
      "receipt",
      requestParams
    );
    this.receiptResponseData = receiptResponse.data;
    this.loading = false;
    this.receiptsSkeleton = false;
    requestParams.includes =
      '["InstructedDispatch","InstructedDispatch.Driver","InstructedDispatch.Instruction","InstructedDispatch.Instruction.District","InstructedDispatch.Instruction.Warehouse", "InstructedDispatch.Dispatcher","ReceivedCommodities","ReceivedCommodities.Commodity"]';
    const iReceiptResponse = await this.apiService.getAll(
      "instructedReceipt",
      requestParams
    );
    this.iReceiptResponseData = iReceiptResponse.data;
    this.iLoading = false;
    this.iReceiptsSkeleton = false;
    let userResponse = await this.userService.getAll();
    this.users = userResponse.data.data;
  }

  async created(): Promise<void> {
    await this.initialize();
  }
  async setReceipts(event: any, pageNumber: number): Promise<void> {
    console.log(pageNumber)
    this.receiptsSkeleton = true;
    let requestParams = this.receiptResponseData.requestParams;
    requestParams.pageNumber = pageNumber;
    const response = await this.apiService.getAll("receipt", requestParams);
    this.receiptResponseData = response.data;
    this.receiptsSkeleton = false;
  }
  async setIReceipts(event: any, pageNumber: number): Promise<void> {
    this.iReceiptsSkeleton = true;
    let requestParams = this.iReceiptResponseData.requestParams;
    requestParams.pageNumber = pageNumber;
    const response = await this.apiService.getAll(
      "instructedReceipt",
      requestParams
    );
    this.iReceiptResponseData = response.data;
    this.iReceiptsSkeleton = false;
  }
  visitRoute(link: string): void {
    this.$router.push(link);
  }
  viewReceiptModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "viewReceiptModal" + i,
      showOverlay: false,
    };
  }
  viewIReceiptModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "viewIReceiptModal" + i,
      showOverlay: false,
    };
  }
}
